import { getField, updateField } from 'vuex-map-fields'
import requests from '../../requests'
import { BILLINGS_URL } from '~/assets/variables/endpoints'

export const state = () => ({
  isLoading: false,
  response: [],
  isUsingChatbot: false,
  isLoadingAskAirene: false,
  responseAskAirene: [],
  isUsingAskAirene: false,
})

export const getters = {
  getField,
}

export const actions = {
  getBasicPackage({ commit }, params) {
    commit('UPDATE_LOADING', true)
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${BILLINGS_URL}/chatbot_ai`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_DATA', res)
            commit('UPDATE_IS_USING_CHATBOT', true)
            commit('UPDATE_LOADING', false)
            resolve(1)
          },
          (err) => {
            commit('UPDATE_DATA', [])
            commit('UPDATE_IS_USING_CHATBOT', false)
            commit('UPDATE_LOADING', false)
            resolve(0)
            reject(err)
          }
        )
    })
  },
  getBasicPackageAirene({ commit }, params) {
    commit('UPDATE_LOADING_ASK_AIRENE', true)
    return new Promise((resolve, reject) => {
      requests.whatsapp
        .getService(
          `${BILLINGS_URL}/airene`,
          {},
          { Authorization: this.$auth.getToken('hub') }
        )
        .subscribe(
          (res) => {
            commit('UPDATE_DATA_ASK_AIRENE', res)
            commit('UPDATE_IS_USING_ASK_AIRENE', true)
            commit('UPDATE_LOADING_ASK_AIRENE', false)
            resolve(1)
          },
          (err) => {
            commit('UPDATE_DATA_ASK_AIRENE', [])
            commit('UPDATE_IS_USING_ASK_AIRENE', false)
            commit('UPDATE_LOADING_ASK_AIRENE', false)
            resolve(0)
            reject(err)
          }
        )
    })
  },
}

export const mutations = {
  updateField,
  // Chatbot
  UPDATE_LOADING(state, payload) {
    state.isLoading = payload
  },
  UPDATE_DATA(state, payload) {
    state.response = payload
  },
  UPDATE_IS_USING_CHATBOT(state, payload) {
    state.isUsingChatbot = payload
  },
  // Airene
  UPDATE_LOADING_ASK_AIRENE(state, payload) {
    state.isLoadingAskAirene = payload
  },
  UPDATE_DATA_ASK_AIRENE(state, payload) {
    state.responseAskAirene = payload
  },
  UPDATE_IS_USING_ASK_AIRENE(state, payload) {
    state.isUsingAskAirene = payload
  },
}
